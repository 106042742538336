import { LayoutsComponent } from './template1/layouts/layouts.component';
import { LayoutsComponent2 } from './template2/layouts/layouts.component';
import { LayoutsComponent3 } from './template3/layouts/layouts.component';
import { LayoutsComponent4 } from './template4/layouts/layouts.component';

export const SettingsRouting: any = [];

SettingsRouting['template1'] = {
  path: '', component: LayoutsComponent,
  loadChildren: () => import('./template1/pages/pages.module').then(m => m.PagesModule)
}

SettingsRouting['template2'] = {
  path: '', component: LayoutsComponent2,
  loadChildren: () => import('./template2/pages/pages.module').then(m => m.PagesModule)
}

SettingsRouting['template3'] = {
  path: '', component: LayoutsComponent3,
  loadChildren: () => import('./template3/pages/pages.module').then(m => m.PagesModule)
}

SettingsRouting['template4'] = {
  path: '', component: LayoutsComponent4,
  loadChildren: () => import('./template4/pages/pages.module').then(m => m.PagesModule)
}
