import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

if (window.location.hostname !== 'localhost' && 'serviceWorker' in navigator) {
  navigator.serviceWorker.register('service-worker.js')
    .then(registration => {})
    .catch(error => {});
}

(window as any).addEventListener('unhandledrejection', (event: any) => {
  if (event.reason && event.reason.message && event.reason.message.includes('ChunkLoadError')) {
    console.warn('ChunkLoadError detected, reloading the page...');
    window.location.href = window.location.origin + window.location.pathname + '?nocache=' + new Date().getTime();
  }
});
